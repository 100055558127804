
import { Component, Mixins,  } from 'vue-property-decorator';
import SingleImageUpload from '@/component/single-image-upload.vue';

import { request as axios } from '@/util/request';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import NEditor from '@/component/NForm/NEditor/NEditor.vue';

@Component({
  name:'Invitingset',
  components: {
    SingleImageUpload,
    NEditor
  },
})
export default class Invitingset extends Mixins(Validate) {
    form_item_layout: any = {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
    };
    // 页面加载
    is_loading: boolean = false

    // 图片
    preview_image: any = "" 
    preview_visible: any = false
    dis: boolean = true
    // 表单
    form = {
        status:0,
        image:[],
        amount_shown:"",
        bonus_rule_order_switch : 0,
        bonus_rule_order_count:1,
        inviter_bonus:"",
        newbie_bonus:"",
        window_pop_count:1,
        rule:'<p>hello</p>'
    }
    validator_list: any = [
        {
            field : "status",
            message: '请选择骑手邀请是否开启',
        },
        {
            field : "image",
            message: '请上传骑手邀请页面',
        },
        {
            field : "amount_shown",
            message:"请输入显示金额"
        },
        {
            field : "inviter_bonus",
            message:"请输入骑手邀请奖励金额"
        },
        {
            field : "newbie_bonus",
            message:"请输入新骑手注册奖励金额"
        },
        {
            field : "window_pop_count",
            message:"请输入引导弹窗"
        }
    ]
    validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);

    handeleSwitch(){
        if(this.form.bonus_rule_order_switch==1){
            this.form.bonus_rule_order_switch = 0
        }else{
            this.form.bonus_rule_order_switch = 1
        }
    }
    compile(){
        this.dis = !this.dis
    }
    handlePreview(imageUrl: string) {
        this.preview_image = imageUrl;
        this.preview_visible = true;
    }
    beforeCommonUpload(file: any) {
        if (file.size / 1024 / 1024 > 2) {
        this.$message.error('上传图片大小不能超过2M');
        return false;
        }
    }
    handlePreviewCancel() {
        this.preview_visible = false;
    }
    async save(){
        if (!this.validateCommit()) {
            this.$message.error("必填项未填写")
            return;
        }
        const data = {
            ...this.form,
            inviter_bonus : (this.form.inviter_bonus as any) * 100,
            newbie_bonus : (this.form.newbie_bonus as any) * 100,
        }
        const res = await axios.post("/boss/knight/setting/inviting",data)
        if(res.status !==200){
            this.$message.error((res as any).message)
        }else{
            this.$message.success("保存成功")
            this.dis = true
        }
    }
    async created(){
        const res = await axios.get("/boss/knight/setting/inviting")
        if(res.status !==200 ){
            this.$message.error((res as any).message)
            return
        }
        if(res.data && res.data !== null ){
            if(res.data?.length || res.data?.length == 0){
                return
            }
            this.form ={
                ...res.data,
                inviter_bonus : res.data.inviter_bonus? res.data.inviter_bonus/100 : 0,
                newbie_bonus : res.data.newbie_bonus?res.data.newbie_bonus/100 : 0,
                bonus_rule_order_switch : /^[0-9]+.?[0-9]*$/.test(res.data.bonus_rule_order_switch)?res.data.bonus_rule_order_switch:1
            }
        }
    }

}
