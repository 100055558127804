
import { Component, Prop, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import oss from 'baimahu/aliyun/oss';
const { VUE_APP_API: API } = process.env;
import ImageCropper from '@/component/upload-image-cropper.vue';
@Component({
  components: {
    ImageCropper,
  },
})
export default class SingleImageUpload extends Vue {
  @Prop({ type: String, default: 'default' }) readonly dir!: string;
  @Prop({ type: String, default: '' }) readonly accept!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: Function }) readonly beforeUpload!: any;
  @Prop({ type: String, default: '' }) imageUrl!: string;
  @Prop({ type: String, default: '' }) shape!: string; // 需求上有要求圆形的样式，此参数用来判断用圆形还是矩形
  @Prop({ type: Boolean, default: false }) noCropper!: boolean;
  @Prop({ type: String, default: '' }) operate!: string; // 判断是否启用某些功能
  @Prop({ type: String, default: '' }) is_large!: boolean; // 需求上有要求圆形的样式，此参数用来判断用圆形还是矩形
  loading: boolean = false;
  tempImageFile: File | null = null;
  cropperPromiseResolve: any;


  handleChange(info: any) {
    if (info.file.status === 'uploading') {
      this.loading = true;
      return;
    }
  }

  handlePreview() {
    this.$emit('preview', this.imageUrl);
  }

  handleRemove() {
    this.$emit('update:imageUrl', '');
  }

  beforeFileUpload(file: File) {
    if (!this.noCropper) {
      return new Promise((resolve, reject) => {
        this.tempImageFile = file;
        this.cropperPromiseResolve = resolve;
      }).then(croppedFile => {
        // croppedFile: file or false, false 直接上传
        if (!(this.beforeUpload && typeof this.beforeUpload === 'function')) {
          return Promise.resolve(croppedFile);
        }
        if (!croppedFile) {
          const beforeUpload = this.beforeUpload(file);
          return typeof beforeUpload === 'boolean' && !beforeUpload
            ? Promise.reject(null)
            : beforeUpload;
        }
        const beforeUpload = this.beforeUpload(croppedFile);
        if (beforeUpload === false) {
          return Promise.reject(null);
        }
        if (beforeUpload && typeof beforeUpload.then === 'function') {
          // is beforeUpload a Promise?
          return beforeUpload
            .then(() => Promise.resolve(croppedFile))
            .catch(() => Promise.reject(null));
        }
        return Promise.resolve(croppedFile);
      });
    }

    if (this.beforeUpload && typeof this.beforeUpload === 'function') {
      return this.beforeUpload(file);
    }
  }

  crop(file: File | boolean) {
    this.cropperPromiseResolve(file);
  }

  @changeLoading(['loading'])
  async request(obj: any) {
    // const { src } = await oss.upload(`https://${API}/boss/aliyun/oss/policy/new`, obj.file);
    let { src } = await oss.upload(`https://${API}/boss/common/oss`, obj.file, true);
    src = src.indexOf('http') === -1 ? `https:${src}` : src;
    this.$emit('update:imageUrl', src);
  }
}
